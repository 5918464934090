import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import BlockContent from '@sanity/block-content-to-react';
import tw from 'twin.macro';

import PageContainer from '../components/page-container';
import LodgingSummary from '../components/lodging-summary';
import Card from '../components/card';
import Callout from '../components/callout';
import Hero from '../components/hero';
import SEO from '../components/seo';
import useLodgingData from '../hooks/use-lodging-data';
import {
  ImageContainer,
  PageTitle,
  FlexContainer,
  FlexItem,
  PageSection,
} from '../utils/elements.stc';

const HomePageTitle = tw(PageTitle)`pt-4`;
const AboutText = tw(FlexItem)`w-full md:text-lg md:pr-8 md:w-1/2`;
const MapWrapper = tw(FlexItem)`w-full md:w-1/3`;
const CardContainer = tw(PageSection)`w-full bg-primary-100 md:pb-0 md:px-4`;
const CardTitle = tw(PageTitle)`md:text-center md:pb-8`;
const CardWrapper = tw(FlexContainer)`w-full`;

const IndexPage = ({ data }) => {
  const {
    page: {
      seo: { title, description, keywords },
      _rawBody,
      headerImage: {
        asset: { fluid: headerImage },
      },
      _rawPrivateWaterText,
      privateWaterImage: {
        asset: { fluid: privateWaterFluid },
      },
    },
    staticMap: {
      childFile: {
        childImageSharp: { fluid: staticMapFluid },
      },
    },
  } = data;

  const lodgingData = useLodgingData();

  const mapUrl =
    'https://www.google.com/maps/search/?api=1&query=39.3254015,-106.9663801&query_place_id=ChIJKbxXpM5KQIcR6V3AXeJv1UE';
  const located =
    'Located 5.6 miles from Basalt, CO at the confluence of Taylor Creek and The Frying Pan River.';

  return (
    <PageContainer>
      <SEO title={title} description={description} keywords={keywords} />
      <Hero image={headerImage.src} />
      <Callout title="Call For Reservations" />
      <PageSection>
        {title && <HomePageTitle>{title}</HomePageTitle>}
        <FlexContainer tw="md:pb-4">
          <AboutText tw="md:leading-8 md:text-xl md:font-hairline">
            {_rawBody && <BlockContent blocks={_rawBody} />}
          </AboutText>
          <MapWrapper>
            <p tw="uppercase text-base">{located}</p>
            {staticMapFluid && (
              <div tw="w-full">
                <ImageContainer>
                  <Img
                    fluid={staticMapFluid}
                    alt="Static map of area showing cabin location as red pin."
                    tw="rounded border-2 border-gray-100 shadow-md"
                  />
                </ImageContainer>
              </div>
            )}
            <a
              href={mapUrl}
              tw="block underline uppercase text-base text-center"
              target="_blank"
              rel="noopener noreferrer"
            >
              View in Google Maps
            </a>
          </MapWrapper>
        </FlexContainer>
      </PageSection>
      <CardContainer>
        <CardTitle>Lodging</CardTitle>
        <CardWrapper>
          {lodgingData &&
            lodgingData.map(item => (
              <Card
                title={item.seo.title}
                fluid={item.cardImage.asset.fluid}
                alt={item.cardImage.alt}
                url={`lodging/${item.seo.slug.current}`}
                key={item._id}
              >
                <LodgingSummary
                  guests={item.quantities.guests}
                  bedrooms={item.quantities.bedrooms}
                  beds={item.quantities.beds}
                  bathrooms={item.quantities.bathrooms}
                />
              </Card>
            ))}
        </CardWrapper>
      </CardContainer>
      <PageSection tw="md:pt-16">
        <FlexContainer>
          <div tw="md:w-1/2 md:pr-16 box-border">
            <PageTitle>Private Water Access</PageTitle>
            {_rawPrivateWaterText && (
              <BlockContent blocks={_rawPrivateWaterText} />
            )}
          </div>
          <div tw="w-full md:w-1/2">
            <ImageContainer>
              <Img
                fluid={privateWaterFluid}
                alt="frying Pan Water private water"
                tw="border-2 border-gray-100 rounded"
              />
            </ImageContainer>
          </div>
        </FlexContainer>
      </PageSection>
    </PageContainer>
  );
};

export default IndexPage;

export const query = graphql`
  query HomePageQuery {
    page: sanityHomePage {
      seo {
        title
        keywords
        description
      }
      _rawBody(resolveReferences: { maxDepth: 10 })
      headerImage {
        asset {
          fluid(maxWidth: 1600) {
            ...GatsbySanityImageFluid
          }
        }
      }
      _rawPrivateWaterText(resolveReferences: { maxDepth: 10 })
      privateWaterImage {
        asset {
          fluid(maxWidth: 800) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
    staticMap(nickname: { eq: "homePage" }) {
      childFile {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`;
