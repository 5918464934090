import { useStaticQuery, graphql } from 'gatsby';

const useLodgingData = (filter = 'all') => {
  const data = useStaticQuery(graphql`
    query {
      allSanityLodging {
        nodes {
          _id
          seo {
            title
            slug {
              current
            }
          }
          quantities {
            bathrooms
            bedrooms
            beds
            guests
          }
          cardImage {
            alt
            caption
            asset {
              fluid(maxWidth: 350) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
  `);

  if (filter === 'all') {
    return data.allSanityLodging.nodes;
  }
};

export default useLodgingData;
