import React from 'react';
import tw, { styled } from 'twin.macro';

import useCompanyInfo from '../hooks/use-company-info';

const CalloutContainer = styled.div`
  ${tw`font-body font-thin uppercase text-xl text-txt-200 text-center py-2 shadow-lg border-t-8 border-b-8 border-solid border-primary-300`}
  background: linear-gradient(#163e3e, #163e3edb);
`;
const CalloutTitle = styled.p`
  ${tw`mb-0 pb-0`}
`;
const Phone = styled.a`
  ${tw`m-0`}
`;

const Callout = props => {
  const { phoneNum } = useCompanyInfo();
  const { title } = props;

  return (
    <CalloutContainer>
      <CalloutTitle>{title}</CalloutTitle>
      <Phone href={`tel:${phoneNum}`}>{phoneNum}</Phone>
    </CalloutContainer>
  );
};

export default Callout;
