import React from 'react';
import tw, { styled } from 'twin.macro';

const HeroWrapper = styled.section`
  background: url(${props => props.image});
  background-size: cover;
  background-position: center center;
  min-height: 500px;
  width: 100%;
`;

const HeroTextWrapper = styled.figcaption`
  position: absolute;
  bottom: 0;
  background-color: rgba(62, 57, 56, 0.9);
  width: 60%;
  margin-left: 20%;
  text-align: center;
`;

const HeroText = styled.section`
  ${tw`text-xl uppercase py-2`};
  color: rgba(235, 224, 213, 1);
`;

const Hero = props => {
  // eslint-disable-next-line react/prop-types
  const { image, text = '' } = props;

  return (
    <HeroWrapper image={image}>
      <HeroTextWrapper>{text && <HeroText>{text}</HeroText>}</HeroTextWrapper>
    </HeroWrapper>
  );
};

export default Hero;
